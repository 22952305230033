import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { usePreviewData } from '@/prismic/hooks/usePreviewData'
import { useSlices } from '@/prismic/hooks/useSlices'
import Page from '@/templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicModular } = usePreviewData(data)
  const blocks = useSlices(prismicModular?.data?.body)
  const borderBottom = prismicModular.data?.newsletter_signup === 'Yes'
  const topPadding = blocks[0]?.slice_type === 'hero'

  return (
    <Page blocks={blocks} borderBottom={borderBottom} topPadding={topPadding} />
  )
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PagePrismic

export const pageQuery = graphql`
  query ModularByID($id: String!) {
    prismicModular(id: { eq: $id }) {
      uid
      type
      data {
        newsletter_signup
        body {
          ... on PrismicModularBodyHero {
            id
            slice_type
            primary {
              style
            }
            items {
              desktop_image: image {
                fixed(height: 2500, width: 2000) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
                alt
              }
              mobile_image: image {
                fixed(height: 800, width: 800) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
                alt
              }
              image_align
            }
          }
          ... on PrismicModularBodyBigImage {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                raw
              }
              link {
                url
                target
              }
            }
          }
          ... on PrismicModularBodyBodyText {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicModularBodyBlogPosts {
            id
            slice_type
            primary {
              heading {
                text
              }
              max_posts
              layout
            }
          }
          ... on PrismicModularBodyFeaturedProjects {
            id
            slice_type
            items {
              project {
                id
                uid
              }
            }
          }
          ... on PrismicModularBodyProfiles {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicModularBodyTextAccordion {
            id
            slice_type
            primary {
              heading {
                text
              }
              body_text {
                raw
              }
            }
            items {
              heading {
                text
              }
              content {
                raw
              }
            }
          }
        }
      }
    }
  }
`
