import React from 'react'
import PropTypes from 'prop-types'
import { BlockRenderer } from '@/components/BlockRenderer'
import * as styles from './Page.module.scss'

const Page = ({ blocks, borderBottom, topPadding }) => {
  return (
    <div className={styles.Page} data-top-padding={topPadding}>
      <BlockRenderer blocks={blocks} />
      {borderBottom && <div className={styles.border} />}
    </div>
  )
}

Page.defaultProps = {
  topPadding: true,
}

Page.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  borderBottom: PropTypes.bool,
  topPadding: PropTypes.bool,
}

export default Page
